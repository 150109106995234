import img from "src/components/severalbrands/lotties/wantLearnMore3.json";
import TextImageSharedComponent from "@components/severalbrands/homePage/firstSection/textImageSharedComponent";
import WhyPartnerWithUs from "@components/severalbrands/whyPartnerWithUs";
const simplifyingComplexDecisions = {
    img: img,
    mainTitle: "Want to Learn More?",
    mainTitleClassName:
        "font-bold lg:text-[50px] text-[30px] lg:leading-10 text-[#351C4F] text-center lg:text-left",
    paragraphsClassName:
        "lg:mt-5  lg:text-[30px]  text-xl lg:leading-10 text-center lg:text-left font-light",
    paragraphs: [
        "Download our free pdf: “10 Key Considerations Before Partnering With a Lead Generation Company”",
    ],
    imgToLeft: true,
    showButton: true,
    gap: "lg:gap-32",
    mainDivTextMarginTop: " lg:mt-[150px] 3xl:mt-0 ",
    showAnimation: true,
};
export const partnerWitUSData = {
    title: "Why Partner With Us?",
    paragraphs: [
        {
            text: `At Several Brands, the businesses we work with are more than
                clients. They are key collaborators and partners. We only
                succeed if you succeed. When you partner with us, you can expect
                a comprehensive, end-to-end marketing solution that delivers
                results. From lead generation to performance marketing to
                analytics and reporting, we have the expertise to help your
                business succeed.`,
            className: "default",
        },
        {
            text: `Contact us today to learn more about how we can help you achieve
                your marketing goals.`,
            className:
                "mt-6  3xl:max-w-[1040px] 3xl:text-xl max-w-[740px]  font-light",
        },
    ],
    buttonText: "Partner With Us",
};

export default function ForthSection() {
    return (
        <section className="lg:mb-52 mb-36 mt-16 lg:mt-0 ">
            <div className="lg:mb-20 lg:mt-28 ">
                <TextImageSharedComponent info={simplifyingComplexDecisions} />
            </div>

            <div className="mt-32 lg:mt-10 ">
                <WhyPartnerWithUs partnerWitUSData={partnerWitUSData} />
            </div>
        </section>
    );
}
