import React, { ReactElement, useRef, useEffect } from "react";
import { Player } from "@lottiefiles/react-lottie-player";
import { motion, useInView, useAnimation } from "framer-motion";
import { JSONValue } from "@lib/shared/types";
import SharedButton from "@components/severalbrands/sharedButton";
import useWindowSize from "@hooks/useWindowSize";
export default function TextImageSharedComponent({
    info,
}: {
    info: {
        mainTitle?: string;
        secondTitle?: string;
        paragraphs?: string[];
        img?: string | JSONValue;
        imgToLeft?: boolean;
        showButton?: boolean;
        buttonTitle?: string;
        mainTitleClassName: string;
        paragraphsClassName: string;
        gap?: string;
        mainDivTextMarginTop?: string;
        showAnimation?: boolean;
        mainH1Element?: boolean;
    };
}): ReactElement {
    const mainSectionDevRef = useRef(null);
    const isInView = useInView(mainSectionDevRef, { once: true });
    const windowSize = useWindowSize();
    const mainEl = useAnimation();
    const firstEl = useAnimation();
    const secondEl = useAnimation();
    useEffect(() => {
        if (isInView) {
            mainEl.start("visible").catch(() => {});
            firstEl.start("visible").catch(() => {});
            secondEl.start("visible").catch(() => {});
        }
    }, [isInView]);
    return (
        <motion.section
            ref={mainSectionDevRef}
            variants={{
                visible: info.showAnimation
                    ? { y: [150, 0], opacity: [0, 1] }
                    : {},
            }}
            animate={mainEl}
            transition={{ duration: 1.5 }}
            className={`flex flex-col lg:flex-row 3xl:justify-between 3xl:items-center  ${
                info.gap ? info.gap : info.imgToLeft ? "lg:gap-28" : "lg:gap-4"
            }  gap-8  `}
        >
            <motion.div
                variants={{
                    visible:
                        windowSize.width < 1024
                            ? { y: [150, 0], opacity: [0, 1] }
                            : {
                                  //   opacity: [0, 1],
                              },
                }}
                transition={{ duration: 1.5 }}
                animate={firstEl}
                className={`max-w-[700px]  3xl:max-w-[1000px]  mx-auto lg:mx-0  ${
                    info.mainDivTextMarginTop ? info.mainDivTextMarginTop : ""
                }  ${info.imgToLeft ? "order-2 " : "order-2 lg:order-1"}`}
            >
                {info.mainTitle &&
                    (info.mainH1Element ? (
                        <h1
                            className={
                                info.mainTitleClassName
                                    ? info.mainTitleClassName
                                    : "font-bold lg:text-6xl text-3xl lg:leading-[79px] 3xl:text-[5.5rem] 3xl:leading-[6.5rem] text-[#351C4F] "
                            }
                        >
                            {info.mainTitle}
                        </h1>
                    ) : (
                        <h2
                            className={
                                info.mainTitleClassName
                                    ? info.mainTitleClassName
                                    : "font-bold lg:text-6xl text-3xl lg:leading-[79px] 3xl:text-[5.5rem] 3xl:leading-[6.5rem] text-[#351C4F] "
                            }
                        >
                            {info.mainTitle}
                        </h2>
                    ))}

                {info.secondTitle && (
                    <h3 className=" font-semibold text-2xl lg:text-4xl 3xl:text-5xl 3xl lg:leading-[45px] 3xl:leading-[3.5rem] mt-2 3xl:mt-4 text-[#351C4F]">
                        {info.secondTitle}
                    </h3>
                )}
                {info.paragraphs && info.paragraphs?.length > 0 && (
                    <>
                        {info.paragraphs?.map((pInfo, index) => (
                            <p
                                key={index}
                                className={
                                    info.paragraphsClassName
                                        ? info.paragraphsClassName
                                        : "lg:mt-8 mt-6 font-light lg:text-lg 3xl:text-[26px] 3xl:leading-[2.5rem] text-base lg:leading-8 "
                                }
                            >
                                {pInfo}
                            </p>
                        ))}
                    </>
                )}
                {info.showButton &&
                    (info.buttonTitle ? (
                        <div className="lg:mt-12 mt-7 w-fit mx-auto lg:mx-0">
                            <SharedButton regularButton scrollButton>
                                {info.buttonTitle}
                            </SharedButton>
                        </div>
                    ) : (
                        <div className="lg:mt-12 mt-7 w-fit mx-auto lg:mx-0">
                            <SharedButton href="/key-considerations">
                                Claim Your Free Guide
                            </SharedButton>
                        </div>
                    ))}
            </motion.div>
            {info.img && (
                <motion.div
                    variants={{
                        visible:
                            windowSize.width < 1024
                                ? {
                                      y: [150, 0],
                                      opacity: [0, 1],
                                      transition: { duration: 1.5 },
                                  }
                                : {
                                      //   scale: [0.2, 1],
                                      //   opacity: [0, 1],
                                      //   transition: { duration: 1.5 },
                                  },
                    }}
                    animate={secondEl}
                    className={` w-[100%]  md:w-[520px] md:min-w-[520px] md:h-[520px] 3xl:w-[800px] 3xl:h-[800px] relative  mx-auto lg:mx-0  ${
                        info.imgToLeft ? "order-1" : "order-1 lg:order-2"
                    } `}
                >
                    <Player autoplay loop src={info.img}></Player>
                </motion.div>
            )}
        </motion.section>
    );
}
